const UnAuthApp: React.FC = () => {
  return (
    <div className="page">
      <div className="intro">
        <p>Please access this site through a 360 survey set up link.</p>
        <a href="https://pxhub.io">The People Experience Hub</a>
      </div>
    </div>
  );
};

export default UnAuthApp;
