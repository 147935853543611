import { IEmployeeSearchResult } from "../types";

interface IEmployeeListProps {
  employee: IEmployeeSearchResult;
  addUserToSurvey?: (username: string) => Promise<void>;
}

const EmployeeListItem: React.FC<IEmployeeListProps> = ({
  employee,
  addUserToSurvey,
}) => {
  return (
    <div className="list-item">
      <ul>
        <li>{employee.employeeName}</li>
        <p>
          {employee.level1}, {employee.level2}
        </p>
      </ul>
      {!!addUserToSurvey && (
        <div
          onClick={() => addUserToSurvey(employee.username)}
          className="list-item__add"
        >
          <span>Add</span>
        </div>
      )}
    </div>
  );
};

export default EmployeeListItem;
