import React, { useEffect, useState } from "react";
import { IThreeSixtySurvey } from "./types";
import * as api from "./api";
import { AxiosError } from "axios";
import UnAuthApp from "./components/pages/UnAuthApp";
import ErrorMessage from "./components/errorMessage/ErrorMessage";
import RightPanel from "./components/pages/app/RightPanel";
import LeftPanel from "./components/pages/app/LeftPanel";

interface IAppProps {
  match: {
    params: {
      linkCode?: string;
    };
  };
}

const App: React.FC<IAppProps> = ({ match }) => {
  const [survey, setSurvey] = useState<IThreeSixtySurvey | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [showAddDrawer, setShowAddDrawer] = useState(false);

  const linkCode = match.params.linkCode;

  const errorHandler = (err: AxiosError) => {
    setLoading(false);
    console.log(err);
    setError("Oops, something has gone wrong");
  };

  const getSurvey = async () => {
    try {
      if (!linkCode) {
        setError("Please ensure your link is correct");
        return;
      }
      setLoading(true);
      api.axiosSetUp(linkCode, errorHandler);
      const surveyFromApi = await api.getThreeSixtySurvey();
      setSurvey(surveyFromApi);
      setLoading(false);

      //sort api to fix failed link code
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getSurvey();
    // eslint-disable-next-line
  }, [linkCode]);

  if (!linkCode) {
    return <UnAuthApp />;
  }

  return (
    <div className="app__container">
      {survey && <LeftPanel survey={survey} />}
      <ErrorMessage error={error} setError={setError} />
      {survey && (
        <RightPanel
          survey={survey}
          loading={loading}
          setLoading={setLoading}
          linkCode={linkCode}
          showAddDrawer={showAddDrawer}
          setShowAddDrawer={setShowAddDrawer}
        />
      )}
    </div>
  );
};

export default App;
