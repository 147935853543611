import React, { Fragment, useEffect, useState } from "react";
import { IEmployeeSearchResult, IThreeSixtySurvey } from "../../../types";
import EmployeeListItem from "../../EmployeeList";
import FakeUsers from "../../fakeUsers/FakeUsers";
import * as api from "../../../api";
import SearchPopUp from "../../searchPopUp/SearchPopUp";
import { MagnifyingGlass } from "phosphor-react";

interface Props {
  survey: IThreeSixtySurvey;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  linkCode: string;
  showAddDrawer: boolean;
  setShowAddDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

const RightPanel: React.FC<Props> = ({
  survey,
  loading,
  setLoading,
  linkCode,
  showAddDrawer,
  setShowAddDrawer,
}: Props) => {
  const [searchResults, setSearchResults] = useState<
    IEmployeeSearchResult[] | undefined
  >([]);
  const [recipients, setRecipients] = useState<
    IEmployeeSearchResult[] | undefined
  >([]);
  const [empSearch, setEmpSearch] = useState("");
  const [showSearch, setShowSearch] = useState<boolean>(false);

  const getSurveyRecipients = async () => {
    const recipientsFromApi = await api.getThreeSixtyRecipients();
    setRecipients(recipientsFromApi);
    console.log(recipientsFromApi);
  };

  const addUserToSurvey = async (username: string) => {
    if (!survey) {
      return;
    }
    setLoading(true);
    await api.addUserToSurvey(survey.id, username);
    await Promise.all([handleSearchFormSubmit(), getSurveyRecipients()]);
    setLoading(false);
  };

  const handleSearchFormSubmit = async (e?: React.FormEvent) => {
    e?.preventDefault();
    if (!empSearch || !survey) {
      return;
    }

    setLoading(true);
    const searchResultsFromApi = await api.searchForEmployee(
      survey.id,
      empSearch
    );
    setSearchResults(searchResultsFromApi);

    setLoading(false);
  };

  useEffect(() => {
    getSurveyRecipients();
  }, [linkCode]);

  useEffect(() => {
    if (!showAddDrawer) {
      setSearchResults([]);
      setEmpSearch("");
    }
  }, [showAddDrawer]);

  useEffect(() => {
    if (!showSearch) {
      setShowSearch(true);
    }
    if (!loading) {
      setLoading(true);
    }
    if (empSearch.length === 0) {
      setShowSearch(false);
    }

    const delayDebounceFn = setTimeout(() => {
      handleSearchFormSubmit();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empSearch]);

  return (
    <div className="panel right">
      <div className="panel__container">
        <h1>Let's Begin</h1>
        <FakeUsers />
        <div className={`search${showAddDrawer ? " search--show" : ""}`}>
          <h3>Search for a colleague to add</h3>
          {!!survey && (
            <Fragment>
              <form
                action=""
                onSubmit={handleSearchFormSubmit}
                className="form"
              >
                <div className="input__container">
                  <MagnifyingGlass size="20" weight="bold" />
                  <input
                    id="employee-search"
                    name="employee-search"
                    type="text"
                    value={empSearch}
                    onChange={(e) => setEmpSearch(e.target.value)}
                    placeholder="Search by name..."
                  />
                </div>
                {/* <button disabled={!empSearch}>Search</button> */}
              </form>
            </Fragment>
          )}
          {showSearch && (
            <SearchPopUp
              loading={loading}
              searchResults={searchResults}
              addUserToSurvey={addUserToSurvey}
              setShowSearch={setShowSearch}
            />
          )}
        </div>
        {recipients && recipients.length > 0 && (
          <div className="recipients">
            <h2>People already invited to your survey:</h2>
            {recipients.map((employee: IEmployeeSearchResult, i: number) => (
              <EmployeeListItem employee={employee} key={i} />
            ))}
          </div>
        )}

        <footer>
          <small>
            Please contact{" "}
            <a href="mailto:support@pxhub.io">support@pxhub.io</a> if you have
            any issues.
          </small>
        </footer>
      </div>
    </div>
  );
};

export default RightPanel;
