import React from "react";
import { IThreeSixtySurvey } from "../../../types";

interface Props {
  survey: IThreeSixtySurvey;
}

const LeftPanel: React.FC<Props> = ({ survey }: Props) => {
  const splitName = (name: string) =>
    name.split(" ").length > 1 ? name.split(" ")[0] : name;

  return (
    <div className="panel left">
      <div className="panel__container">
        <div className="logo">
          <img
            src={
              survey.userConfig.logoPath
                ? `https://cdn.pxhub.io/img/${survey.userConfig.logoPath}`
                : "https://cms.pxhub.io/uploads/fe9eaa57da5e4198b73e514581af94d3.png"
            }
            alt="TBC"
          />
        </div>

        <div className="intro">
          <h1>Invite people to provide 360 Feedback​</h1>
          <h2>Dear {splitName(survey.userConfig.fullName)},</h2>
          <br></br>
          <p>
            Welcome to your 360 Feedback assessment titled - {survey.name}.
            Please select a range of recipients to include, as far as is
            possible:​
          </p>
          <ul>
            <li>Your Line Manager​</li>
            <li>Your Direct Teports</li>
            <li>Your Team-mates and other Internal Colleagues</li>
          </ul>

          <p>
            For help & information, please view this{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://docs.pxhub.io/welcome/frequently-asked-questions"
            >
              guide
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LeftPanel;
