import Axios, { AxiosError } from 'axios'
import { IAddUserToSurveyResult, IEmployeeSearchResult, IThreeSixtySurvey } from '../types'

const surveyApiUrl = process.env.REACT_APP_SURVEY_API_URL || 'https://dev.survey.api.pxhub.io'

const axios = Axios.create({
    baseURL: surveyApiUrl,
})


type IErrorHandler = (err: AxiosError) => void
let errorHandler: IErrorHandler | undefined

export const axiosSetUp = (linkCode: string, incomingErrorHandler: IErrorHandler) => {
    axios.defaults.headers = {
        'x-link-code': linkCode
    }

    errorHandler = incomingErrorHandler
}

export const getThreeSixtySurvey = async () => {
    try {
        const res = await axios.get<IThreeSixtySurvey>('/three-sixty/survey')
        return res.data
    } catch (error) {
        if (errorHandler) errorHandler(error)
    }
}

export const searchForEmployee = async (surveyId: number, query: string) => {
    try {
        const res = await axios.get<IEmployeeSearchResult[]>(
            `/employee-search/${surveyId}`,
            { params: { q: query } }
        )
    
        return res.data
    } catch (error) {
        if (errorHandler) errorHandler(error)
    }
}

export const addUserToSurvey = async (surveyId: number, username: string) => {
    try {
        const res = await axios.post<IAddUserToSurveyResult>(`/lifecycle/v2/${surveyId}`, { username, sendEmail: true })
        return res.data
    } catch (error) {
        if (errorHandler) errorHandler(error)
    }
}

export const getThreeSixtyRecipients = async () => {
    try {
        const res = await axios.get<IEmployeeSearchResult[]>('/three-sixty/recipients')
        return res.data
    } catch (error) {
        if (errorHandler) errorHandler(error)
    }
}
