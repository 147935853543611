import React from "react";
import Image1 from "../../images/1.jpg";
import Image2 from "../../images/2.jpg";
import Image3 from "../../images/3.jpg";
import Image4 from "../../images/4.jpg";
import Image5 from "../../images/5.jpg";
import Image6 from "../../images/6.jpg";
import Image7 from "../../images/7.jpg";
import Image8 from "../../images/8.jpg";

const FakeUsers = () => {
  const images = [
    Image1,
    Image2,
    Image3,
    Image4,
    Image5,
    Image6,
    Image7,
    Image8,
  ];
  return (
    <div className="fake__users__container">
      {images.map((image: string, i: number) => (
        <div
          style={{ backgroundImage: `url(${image})` }}
          key={i}
          className="fake__user__item"
        ></div>
      ))}
    </div>
  );
};

export default FakeUsers;
