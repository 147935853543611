
interface IErrorProps {
    error?: string
    setError: (error?: string) => void
}

const ErrorMessage: React.FC<IErrorProps> = ({ error, setError }) => {
    setTimeout(() => {
        setError(undefined)
    }, 5000)

    return (
        <div className={`error${error ? ' error--show' : ''}`}>
            <p>{error ? error : 'Oops!'}</p>
            <p>Please contact <a href="mailto:support@pxhub.io">support@pxhub.io</a> if the problem persists.</p>
        </div>        
    )
}

export default ErrorMessage
